import { getUrlQuery } from "../Utils/getUrlQuery";

export const APP_NAME = 'Liquid Elements'
 export const ELEMENT_NAME = window.location.pathname.split('/').pop();
export const PERSIST_STORE_NAME = `le_${getUrlQuery().get('token')?.substring(14, 50)}_${ELEMENT_NAME}`
export const WHITELISTED_ROUTES = [
  '/elements/cart',
  '/elements/product'
]
export const ALLOWED_URL_PARAMS = [
  'token',
  'f',
  'ref'
]
