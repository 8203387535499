import {StrictMode, useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import UrlParams from "./Utils/UrlParams";

import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import appConfig from 'Configs/app.config';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_ENVIRONMENT !== "dev",
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    new HttpClientIntegration({
      failedRequestStatusCodes: [[500, 505], 507],
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: true,
      fetch: true,
      xhr: true,
      history: true
    })

  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
  sendDefaultPii: true,
});

async function render() {
  const params = new UrlParams(window.location.search)
  appConfig.mockApi = params.get("demo") === "true"
  createRoot(document.getElementById('root'))
    .render(
      <StrictMode>
        <App/>
      </StrictMode>
    );
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
