class UrlParams {
    constructor(search) {
        this.qs = (search).substring(1);
        this.params = {};
        this.parseQueryString();
    }
    parseQueryString() {
        this.qs.split('&').reduce((a, b) => {
            const [key, val] = b.split(/=(.+)/g);
            a[key] = encodeURIComponent(val);
            return a;
        }, this.params);
    }
    get(key) {
        return this.params[key];
    }
}
export default UrlParams