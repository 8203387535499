import { ALLOWED_URL_PARAMS, WHITELISTED_ROUTES } from "../Constants/app.constant";
import { getUrlQuery } from "./getUrlQuery";

export const validRouting = () => {
  try {
    const search = window?.location?.search;
    const pathname = window?.location?.pathname;
    const pathnameTest = WHITELISTED_ROUTES.some(r => r === pathname);
    const searchTest = ALLOWED_URL_PARAMS.some(r => getUrlQuery().get(r));
    return search && pathname && searchTest && pathnameTest
  } catch (e) {
    return false
  }
}
