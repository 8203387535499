import UrlParams from "./UrlParams";

export const getUrlQuery = () => {
  const { search } = window.location;
  const params = new UrlParams(search)
  if (params.get('token')) {
    // TODO: check why this is necessary
    // params.set('token', params.get('token').replace('token=', ''))
  }

  return params;
}
